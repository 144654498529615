.formList {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.deleteFormButton {
  cursor: pointer;
}

.toggleStatusButton {
  cursor: pointer;
}

.formListNewFormSelection {
  float: right;
}

.newFormSourceToggle {
  margin-right: 2em;
  margin-bottom: 1.6em;
}
