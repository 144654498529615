.undoButton,
.clearButton,
.applyDoctorSignatureButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.undoButton:hover,
.clearButton:hover,
.applyDoctorSignatureButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.signatureCanvas,
.blankSignature {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.signatureCanvasReadOnly {
  width: 100% !important;
  max-width: 480px !important;
  height: auto !important;
}

.signatureCanvasEditable {
  width: 100% !important;
  max-width: 480px !important;
  height: auto !important;
}

.blankSignature {
  width: 480px !important;
  height: 300px !important;
}

.signatureCanvasDisabled {
  pointer-events: none;
}

.signatureButtons {
  padding-bottom: 1em;
}

.signatureHeader {
  padding-top: 1.5em !important;
  margin-bottom: 0.2em !important;
}

.canvasSegment {
  padding: 0 !important;
}