body {
    overflow-y: scroll;
  }
   
.pageHeader {
  font-size: 2em;
  font-weight: bold;
}

.imageList {
  display: flex;
  flex-wrap: wrap;
}

.imageName {
  width: 14.5em;
  overflow-wrap: break-word;
}

.imagePropertiesInputs {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.imageCaptionInput {
  width: 14.5em;
  resize: vertical;
}

.imageCaptionInput:disabled {
  background-color: #ffffff;
  opacity: 0.4;
}

.imageCaptionInput {
  width: 14.5em;
  resize: vertical;
  overflow-y: scroll;
}

.imageCaptionInput:disabled {
  background-color: #ffffff;
  opacity: 0.4;
}

.imageTakenOnDateInput {
  padding-bottom: 2em;
}

.editDeleteImageButtons,
.cancelSaveImageButtons {
  margin-left: 2em;
}

.editButton,
.cancelButton,
.saveButton,
.uploadImageButton,
.takePhotoButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.editButton:hover,
.cancelButton:hover,
.saveButton:hover,
.uploadImageButton:hover,
.takePhotoButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.listItem {
  flex-grow: 1;
  flex-basis: 1px;
  margin: 1px;
  width: 18em;
}

.listItem:empty {
  width: 300px;
  height: 0;
}

.uploadButton {
  bottom: 0;
  padding-bottom: 2em;
}

.imageModalImage {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

.imageModalDescription {
  margin-top: 5%;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
}

.imageModalUploadedOnDate,
.imageModalTakenOnDate {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 2%;
}

.photographTags {
  padding-left: 2em;
}

.imageOperations {
  margin-top: 1.4em;
  margin-bottom: 1em;
  color: rgba(0,0,0,.87);
  border-top: 1px solid #D9D9D9;
  padding-top: 1em;
}

.loaderContent {
  height: 10em;
}
