.topMenuImage {
  background-image: url(../../../../assets/images/banner.jpg);
  background-repeat: repeat-x;
}

.logOutButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.logOutButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.topMenuBar {
  padding: 1em 0 !important;
}

.topMenuBar .item {
  margin: 0 3em 0 0 !important;
}

.topMenuBar .icon {
  color: black !important;
}
