.tableHeader {
  font-size: 2em;
  font-weight: bold;
}

.listHeader {
  margin-top: 1em !important;
  float: left;
}

.searchBar {
  margin-top: 2em;
}

.createButton {
  float: right;
}

.addPatientSourceToggle {
  margin-bottom: 1.6em;
}

.addPatientSourceToggle.ui.toggle.checkbox input:focus:checked~.box:before,
.addPatientSourceToggle.ui.toggle.checkbox input:focus:checked~label:before,
.addPatientSourceToggle.ui.toggle.checkbox input:checked~.box:before,
.addPatientSourceToggle.ui.toggle.checkbox input:checked~label:before {
  background-color: #767676 !important;
}

.addPatient {
  float: right;
  color: #ffffff !important;
  background-color: #767676 !important;
}

.searchButton {
  width: 7em;
  color: #ffffff !important;
  background-color: #767676 !important;
}

.addPatient:hover,
.searchButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.PatientTableFunctions {
  padding-bottom: 5em !important;
}

.TableFunctions {
  padding-bottom: 2em !important;
}