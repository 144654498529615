.requiredAsterisk {
  color: #db2828;
}

.errorLabel {
  color: #9f3a38 !important;
}
.errorMessageContainer {
  text-align: center;
  margin-top: 2em;
}

.iconButton {
  cursor: pointer;
}

.centerContent {
  text-align: center;
}

/* Only use in printing to Pdf */
.printToPdfCheckbox {
  float: left !important;
  text-align: center !important;
  width: 17px !important;
  height: 17px !important;
  background: #fff !important;
  border-radius: 0.21428571rem !important;
  border: 1px solid #d4d4d5 !important;
  margin-right: 0.64em !important;
}

.printToPdfCheckboxContent {
  position: relative;
  top: -4px;
  font-weight: 900;
  font-size: 1.2em;
}
