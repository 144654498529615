.doctorNamePrinted {
  margin-left: 0.5em !important;
}

.doctorSignatureArea,
.blankSignature {
  border: 1px solid rgba(34,36,38,.15);
}

.doctorSignatureHeader {
  margin-bottom: 0.2em !important;
}

.doctorSignatureContainer {
  margin: 1em 0 1em 0.5em;
}

.ui.form .doctorNamePrinted input[type="text"] {
  color: rgba(0,0,0,.87)!important;
}

.ui.form .dateDoctorSigned input[type="text"] {
  color: rgba(0,0,0,.87)!important;
}
