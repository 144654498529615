body {
  overflow-y: scroll;
  margin-bottom: 50px;
}

.FormContainer {
  margin: auto;
  margin-left: 2em;
  margin-right: 2em;
}

.FormBody {
  padding-top: 3em;
  padding-bottom: 3em;
}

.noLabelInput {
  padding-top: 1.65em;
}

input[disabled] ~ label {
  opacity: 1 !important;
}

.FormHeader {
  margin-top: 1em;
  font-size: 2em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 13em auto;
  margin-right: 13em auto;
}

.FormSectionHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.FormDatePicker {
  margin-left: 0.5em !important;
}

.FormChildDatePicker {
  margin-left: 1em !important;
}

.datePatientSigned {
  margin: 1em 0em 0em 0.5em !important;
}

.textField {
  white-space: pre-line;
  margin-top: 1em !important;
  text-align: left;
}

.formButtons {
  text-align: center;
}

.FormContainer .editDeleteDownloadButtons,
.FormContainer .cancelSaveDownloadButtons {
  padding-top: 1em;
}

.FormContainer .editDeleteButtons,
.FormContainer .cancelSaveButtons {
  padding-top: 1em;
}

.FormContainer .editButton,
.FormContainer .cancelButton,
.FormContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.FormContainer .editButton:hover,
.FormContainer .cancelButton:hover,
.FormContainer .saveButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.FormContainer .rangeSlider > div {
  width: 95% !important;
  margin: auto;
}

.FormContainer .rangeSlider {
  margin-bottom: 3em !important;
}

/* Copied from Semantic field label properties */
.FormContainer .rangeSliderLabel {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  margin-left: 0.5em !important;
}

.FormContainer .rangeSliderErrorLabel {
  color: #9f3a38;
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  margin-left: 0.5em !important;
}

.FormContainer .textField label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 0.5em !important;
}

.groupChild {
  padding-left: 1em !important;
  margin-left: 0.5em !important;
  border-left: 2px solid #b4b4b4 !important;
  margin-bottom: 1em !important;
}

.patientDataField {
  width: 100%;
  margin: 0.5em !important;
}
