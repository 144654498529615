/* .drawButton,
.eraseButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.drawButton:hover,
.eraseButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
} */

.annotationCanvas {
  width: 100% !important;
  max-width: 480px !important;
  height: auto !important;
  position: absolute;
  touch-action: none !important;
}

.backgroundImage {
  width: 100% !important;
  max-width: 480px !important;
  height: auto !important;
}

.canvasSegment {
  padding: 0 !important;
}
