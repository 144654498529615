body {
  overflow-y: scroll;
}

.createPatientContainer {
  margin: auto;
  min-width: 868px;
  max-width: 2048px;
  margin-left: 2em;
  margin-right: 2em;
}

.patientFormsFieldGroup {
  margin-right: 2em;
}

.details {
  padding-bottom: 3em;
}

.noLabelInput {
  padding-top: 1.65em;
}

.createPatientHeader {
  margin-top: 0;
  font-size: 2em;
  font-weight: bold;
  color: rgba(0,0,0,.87);
  margin-left: 13em auto;
  margin-right: 13em auto;
}

.patientFormSectionHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0,0,0,.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.pwmndDatePickers {
  display: inline-flex;
  width: 100%;
}

.dateOfAssessmentPicker {
  width: 50%;
}

.dateOfDeathPicker {
  width: 40%;
}

.gdprRadioButtons {
  padding-top: 1em;
}

.createPatientContainer .editDeleteButtons,
.createPatientContainer .cancelSaveButtons {
  padding-top: 2em;
  position: relative;
  left: 42%;
}

.createPatientContainer .editButton,
.createPatientContainer .cancelButton,
.createPatientContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.createPatientContainer .editButton:hover,
.createPatientContainer .cancelButton:hover,
.createPatientContainer .saveButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}
