body {
  overflow-y: scroll;
}

.viewFormContainer {
  margin: auto;
  min-width: 1000px;
  max-width: 1024px;
}

.viewModeMedicalTab {
  margin: auto;
  width: 95%;
}

.viewModeMedicalTab .infoLabel {
  font-size: 1.1em;
  margin-right: 0.5em;
  font-weight: 900;
}

.deleteUserSpacer {
  height: 6.2em;
  margin-top: 3.2em;
  min-width: 1000px;
  max-width: 1024px;
}

.deleteButton {
  float: right;
}

.details fieldset {
  border: none;
}

.editToggleButton {
  text-align: right;
}

.viewPatientContainer .privacy .checkbox {
  margin-left: 0.5em !important;
}

.viewPatientContainer .field label {
  opacity: 1 !important;
}

.viewList {
  padding-left: 1.4em !important;
}

.yesNoChild {
  padding-left: 1.4em !important;
  margin-bottom: 1em !important;
}

.yesNoChild .yesNoInput {
  padding-left: 0.5em !important;
  margin-bottom: 0em !important;
}

.yesNoDateInput {
  padding-left: 0.5em !important;
}

input:disabled,
.disabled.field,
.ui.disabled.dropdown {
  opacity: 1 !important;
}

.noLabelInput {
  padding-top: 1.65em;
}

.dateOfBirth {
  margin-left: 0.5em !important;
}

.inputHeader {
  font-size: 2em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 13em auto;
  margin-right: 13em auto;
  min-width: 1024px;
}

.patientSectionHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.personalInformationHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.createEnquiryButton {
  margin-top: 2em !important;
}

.createGrantButton {
  margin-top: 2em !important;
  margin-left: 2em !important;
}

.furtherInformationHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.supportLabels {
  margin-left: 1em;
}

.otherFeaturesHeader {
  margin-top: 1.4em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.patientSettingsHeader {
  padding-top: 1.6em;
  padding-bottom: 0.2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
}

.sectionHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.textArea {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.viewPatientContainer .editActivationButtons,
.viewPatientContainer .editDeleteButtons,
.viewPatientContainer .cancelSaveButtons {
  padding-top: 2em;
  position: relative;
  left: 42%;
}

.viewPatientContainer .editButton,
.viewPatientContainer .cancelButton,
.viewPatientContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.viewPatientContainer .editButton:hover,
.viewPatientContainer .cancelButton:hover,
.viewPatientContainer .saveButton:hover {
  color: #ffffff !important;
  background-color: #606060 !important;
}

.viewPatientContainer .downloadButtons {
  margin-top: 2em;
  position: relative !important;
  left: 41% !important;
}
