.mobileSidebar,
.desktopSidebar {
  background-color: #767676 !important;
  width: 170px !important;
}

.mobileSidebar .hamburger {
  color: white !important;
  padding: 1.5em !important;
  float: left !important;
}

.mobileSidebar img {
  padding: 1em !important;
  float: right !important;
}

.mobileSidebar .divider {
  margin-top: 6em !important;
}

.desktopSidebar img {
  padding: 1em !important;
}
