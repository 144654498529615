.preConsultationSelectContainer {
  margin: auto;
}

.preConsultationSelectForm {
  margin: auto;
  width: 70%;
  padding: 1em 0.8em 3em;
  border: 2px solid #d9d9d9;
}

.preConsultationVerifyContainer {
  margin: auto;
  width: 50% !important;
}

.preconsultationHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.preconsultationContinueButton {
  margin-top: 3em;
  text-align: center;
}

.preconsultationSendEmailButton {
  margin-top: 3em;
  text-align: center;
}

.preconsultationVerifyButton {
  margin-top: 1em;
  text-align: center;
}

/* Bug fix for semantic css issue */
.ui.compact.icon.message {
  width: auto;
}
