.formList {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 120rem;
}

.deleteFormButton {
  cursor: pointer;
}

.toggleStatusButton {
  cursor: pointer;
}
