.treatmentList {
  text-align: left;
  margin: auto;
  min-width: 1000px;
  max-width: 1024px;
}

.tableButton {
  cursor: pointer;
}
