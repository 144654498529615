.sidebarPusher {
  margin: 2rem 4rem 2rem 17rem !important;
  min-height: 100vh;
}

.sidebarOpen {
  margin: 2rem 1rem 2rem 13rem !important;
  min-height: 100vh;
}

.sidebarClosed {
  margin: 2rem 0rem 2rem 0rem !important;
  min-height: 100vh;
}

.loggedOut {
  margin: 2rem 5rem 2rem 5rem !important;
  min-height: 82vh;
}
