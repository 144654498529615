.tableHeader {
  font-size: 2em;
  font-weight: bold;
}

.addForm {
  float: right;
}

.newFormButton {
  float: right !important;
  color: #ffffff !important;
  background-color: #767676 !important;
}

.newFormButton:hover {
  float: right !important;
  color: #ffffff !important;
  background-color: #606060 !important;
}

.preConsultationButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.preConsultationButton:hover {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.formListHeader {
  float: left;
}

.allFormsDropdown {
  display: block !important;
  width: 20%;
  margin: 0 auto;
}