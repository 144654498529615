.Logo {
  background-color: white;
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.Logo img {
  height: 100%;
}

.photoContainer {
  margin: 1em;
  width: 16em;
  height: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoContainer img {
  max-width: 100%;
  max-height: 100%;
}