body {
  overflow-y: scroll;
}

.modifyProductContainer {
  margin: auto;
  max-width: 1024px;
}

.details {
  padding-bottom: 3em;
}

.tableFunctions {
  margin-bottom: 1em;
}

.pageHeader {
  font-size: 2em;
  font-weight: bold;
}

.productInfoHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0,0,0,.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.textArea {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.modifyProductContainer .editDeleteButtons,
.modifyProductContainer .cancelSaveButtons {
  padding-top: 2em;
  position: relative;
  left: 42%;
}

.addProductButton {
  float: right;
}

.modifyProductBatchDatePicker {
  margin-left: 0.5em !important;
}

.addProductButton,
.modifyProductContainer .editButton,
.modifyProductContainer .cancelButton,
.modifyProductContainer .activationButton,
.modifyProductContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.addProductButton:hover,
.modifyProductContainer .editButton:hover,
.modifyProductContainer .cancelButton:hover,
.modifyProductContainer .activationButton:hover,
.modifyProductContainer .saveButton:hover {
  background-color: #606060 !important;
}

.showActiveProductsToggle {
  margin-bottom: 1.6em;
}

.showActiveProductsToggle.ui.toggle.checkbox input:focus:checked~.box:before,
.showActiveProductsToggle.ui.toggle.checkbox input:focus:checked~label:before,
.showActiveProductsToggle.ui.toggle.checkbox input:checked~.box:before,
.showActiveProductsToggle.ui.toggle.checkbox input:checked~label:before {
  background-color: #767676 !important;
}