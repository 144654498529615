body {
  overflow-y: scroll;
}

.modifyUserContainer {
  margin: auto;
  min-width: 1000px;
  max-width: 1024px;
}

.details {
  padding-bottom: 3em;
}

.tableFunctions {
  margin-bottom: 1em;
}

.pageHeader {
  font-size: 2em;
  font-weight: bold;
}

.userInfoHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0,0,0,.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.textArea {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.modifyUserContainer .editDeleteButtons,
.modifyUserContainer .cancelSaveButtons {
  padding-top: 2em;
  position: relative;
  left: 42%;
}

.modifyUserContainer .editButton,
.modifyUserContainer .cancelButton,
.modifyUserContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.modifyUserContainer .editButton:hover,
.modifyUserContainer .cancelButton:hover,
.modifyUserContainer .saveButton:hover {
  background-color: #606060 !important;
}

.addUserButton {
  float: right;
  color: #ffffff !important;
  background-color: #767676 !important;
}

.addUserButton:hover {
  background-color: #606060 !important;
}
