body {
  overflow-y: scroll;
}

.treatmentContainer {
  margin: auto;
  max-width: 1024px;
}

.treatmentFormFieldset {
  min-width: 0 !important;
}

.details {
  padding-bottom: 3em;
}

.ui.table tr td.disabled,
.ui.table tr.disabled td,
.ui.table tr.disabled:hover,
.ui.table tr:hover td.disabled,
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label,
.field.disabled,
.fields.disabled,
.disabled {
  opacity: 1 !important;
}

.tableFunctions {
  margin-bottom: 1em;
}

.pageHeader {
  font-size: 2em;
  font-weight: bold;
}

.treatmentInfoHeader {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.2em;
}

.textArea {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.textAreaDouble {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.treatmentContainer .editDeleteButtons,
.treatmentContainer .cancelSaveButtons {
  padding-top: 2em;
  position: relative;
  left: 42%;
}

.treatmentContainer .downloadButtons {
  margin-top: 5em !important;
  position: relative !important;
  left: 41% !important;
}

.addTreatmentButton {
  float: right;
}

.addFollowUpNoteButton {
  float: right;
  margin-top: 1em !important;
}

.addTreatmentButton,
.addFollowUpNoteButton,
.treatmentContainer .editButton,
.treatmentContainer .cancelButton,
.treatmentContainer .activationButton,
.treatmentContainer .saveButton {
  color: #ffffff !important;
  background-color: #767676 !important;
}

.addTreatmentButton:hover,
.treatmentContainer .editButton:hover,
.treatmentContainer .cancelButton:hover,
.treatmentContainer .activationButton:hover,
.treatmentContainer .saveButton:hover {
  background-color: #606060 !important;
}

.treatmentsListHeader {
  float: left;
}

.allTreatmentsDropdown {
  float: "left";
}

.yesNoChild .field {
  margin-bottom: 0.5em !important;
}

.examinationTextAreas,
.treatmentInformation {
  width: 100%;
}

.examinationNotes,
.examinationDiagnosis,
.examinationDiagnosisViewList,
.patientConcernsTextArea {
  padding-top: 1.8em;
}

.examinationDiagnosisOther {
  margin-top: 1em !important;
  width: 80%;
}

.examinationDiagnosisItem {
  margin-top: 0.5em !important;
}

.treatmentDetails {
  margin-top: 2em !important;
}

.treatmentOther {
  margin-top: 1em !important;
  margin-left: 0em !important;
}

.treatmentGroupSection {
  margin-bottom: 1em !important;
}

.treatmentInformationItem {
  padding-top: 0.5em;
  float: right;
  width: 95%;
}

.treatmentDetails .productTableSegment {
  overflow-x: scroll;
  padding: 0;
}

.patientAestheticConcernMappings {
  margin: 2em 0em 1em;
}

.treatmentDetails .productTable {
  margin-top: 2em !important;
}

.spacedLabel {
  padding-bottom: 1em;
  padding-left: 1em;
}

.doctorHeader,
.doctorCell {
  width: 50% !important;
}

.priceHeader,
.priceCell {
  width: 40% !important;
}

.removeHeader,
.removeCell {
  width: 10% !important;
}

.minimumPeriodHeader {
  margin-bottom: 0.2em !important;
}

.followUpNoteReport {
  margin-left: 0.5em;
}
