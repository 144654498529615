body {
  background-color: #ffffff;
}
body > .grid {
  height: 100%;
}

.content {
  color: #767676;
}

a:link,
a:visited {
  color: #767676;
}

#LoginColumn {
  max-width: 450px;
}

#LoginButton {
  color: #ffffff;
  background-color: #767676;
}

ui.button {
  background-color: rgba(0,0,0,.87);
}

ui.icon.button {
  color: #ffffff;
}

.Login .dropdown .icon {
  float: left !important;
}
